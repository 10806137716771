/** {
  font-family: Helvetica;
}*/

.dashboard-nav {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #000;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}