@font-face {
  font-family: 'Open-sans-condensed-light';
  src: local('Open-sans-condensed-light'), url(../../assets/fonts/open-sans-condensed.light.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

* {
	font-family: 'Open-sans-condensed-light';
}

.website-nav {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  padding-left: 48px;
  /*justify-content: space-between;*/
  /*padding: 1rem;*/
}